<template>
	<div class="components">
		<div class="container">
			<div class="components__header">
				Компоненты
			</div>

			<div class="items">
				<div class="items__title">
					Инпуты (значение: {{test}})
				</div>
				<div class="items__body">
					<div class="item">
						<div class="item__title">
							Обычный инпут
						</div>
						<div class="item__content">
							<b-input label="Логин" v-model="test"></b-input>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							Пароль
						</div>
						<div class="item__content">
							<b-input label="Пароль" password v-model="test"></b-input>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							Ошибка
						</div>
						<div class="item__content">
							<b-input label="Логин" v-model="test" error></b-input>
						</div>
					</div>
				</div>
			</div>


			<div class="items">
				<div class="items__title">
					Кнопки
				</div>
				<div class="items__body">
					<div class="item">
						<div class="item__title">
							кнопка
						</div>
						<div class="item__content">
							<b-button>
								Далее
							</b-button>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							кнопка large
						</div>
						<div class="item__content">
							<b-button
								large
							>
								Войти
							</b-button>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							кнопка large disabled
						</div>
						<div class="item__content">
							<b-button
								large
								disabled
							>
								Войти
							</b-button>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							кнопка large error
						</div>
						<div class="item__content">
							<b-button
								large
								error
							>
								Войти
							</b-button>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							кнопка large error disabled
						</div>
						<div class="item__content">
							<b-button
								large
								error
								disabled
							>
								Войти
							</b-button>
						</div>
					</div>
				</div>
			</div>

			<div class="items">
				<div class="items__title">
					header
				</div>
				<div class="items__body">
					<div class="item">
						<div class="item__content">
							<b-header></b-header>
						</div>
					</div>
				</div>
			</div>

			<div class="items">
				<div class="items__title">
					dropdown
				</div>
				<div class="items__body">
					<div class="item">
						<div class="item__content">
							kek
							<b-dropdown
								title="Платформы"
								close
							>
								element
								
								<template v-slot:dropdown>
									list
								</template>
							</b-dropdown>
							you can see
						</div>
					</div>
					<div class="item">
						<div class="item__content">
							<b-platoform-select></b-platoform-select>
						</div>
					</div>
				</div>
			</div>

			<div class="items">
				<div class="items__title">
					icons
				</div>
				<div class="items__body">
					<div class="item">
						<div class="item__title">
							svg-icon
						</div>
						<div class="item__content">
							<svg-icon icon-class="list" class="list"></svg-icon>
							<svg-icon icon-class="display_list" class="glist"></svg-icon>
							<svg-icon icon-class="platform-apple" class="glist" style="font-size: 18px"></svg-icon>
						</div>
					</div>
					<div class="item">
						<div class="item__title">
							iconbase
						</div>
						<div class="item__content">
							<b-icon><b-apple></b-apple></b-icon>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import header from '@/components/header.vue'
import input from "@/components/basic/input.vue";
import button from "@/components/basic/button.vue";
import dropdown from "@/components/basic/dropdown.vue";
import platformSelect from "@/components/platformSelect.vue";
// import apple from "@/components/basic/icons/apple.vue";

export default {
	name: 'components',
	components: {
		"b-header": header,
		"b-input": input,
		"b-button": button,
		"b-dropdown": dropdown,
		"b-platoform-select": platformSelect,
		// "b-apple": apple,

	},
	data: () => ({
		test: ""
	}),
}
</script>

<style scoped lang="sass">
.components
	height: 100%
	&__header
		font-size: 36px
		margin-bottom: 25px
		padding-top: 15px
		font-weight: 300

.items
	margin-bottom: 30px
	&__title
		margin-left: 10px
		margin-bottom: 5px
		font-size: 24px
		font-weight: 300
	&__body
		padding: 10px
		background-color: $main-background
		border-radius: 12px
		display: flex
		flex-wrap: wrap
		grid-gap: 30px

.item
	&__title
		font-size: 18px
		margin-bottom: 10px

.container
	padding: 0 20px
	background: lighten($gray-light-1, 15%)
	width: 1060px
	margin: 0 auto
	height: 100%

</style>