<template>
	<div
		class="input"
		:class="{ 'focused': focused, 'block': block, 'password': password, 'error': error }"
		@click="inputFocus"
	>
		<input
			class="input__item"
			:type=" password && !showPassword ? 'password' : type "
			:value="modelValue"
			@input="(e) => $emit('update:modelValue', e.target.value)"
			ref="input"
			@focus="() => focused = true"
			@blur="() => focused = false"
			:required="required"
		>
		<span
			v-if="label"
			class="input__label"
			:class="{ 'active': modelValue }"
		>
			{{label}}
		</span>
		<div
			v-if="password"
			class="password-show__wrapper"
			@click.stop="() => showPassword = !showPassword"
		>
			<eye-trigger :closed="showPassword"/>
		</div>
	</div>
</template>

<script>
import eyeTrigger from "./eyeTrigger.vue"

export default {
	name: "b-input",
	components: {
		eyeTrigger
	},
	props: {
		label: {
			type: [String, Number],
			default: ''
		},
		modelValue: {
			default: ''
		},
		password: {
			type: Boolean,
		},
		error: {
			type: Boolean,
		},
		block: {
			type: Boolean,
		},
		type: {
			type: String,
			default: 'text'
		},
		required: {
			type: Boolean
		},
		'group-item': {
			type: Boolean
		}
	},
	data: () => ({
		focused: false,
		showPassword: false,
	}),
	methods: {
		inputFocus(){
			this.$refs.input.focus();
		}
	}
}
</script>

<style scoped lang="sass">
$padding-from-border: 16px
.input
	display: inline-block
	position: relative
	padding-left: $padding-from-border
	padding-right: $padding-from-border
	padding-top: 22px
	padding-bottom: 8px
	background: $white
	border: 1px solid #9F9F9F
	box-sizing: border-box
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08)
	border-radius: 10px
	transition: box-shadow .2s ease
	cursor: pointer

	&__item
		font-family: $main_font
		font-size: 18px
		line-height: 24px
		border: none
		outline: none
		width: 100%

	&__label
		font-size: 18px
		position: absolute
		left: $padding-from-border + 2
		top: 50%
		transform: translateY(-50%)
		color: $gray
		transition: font-size .2s ease, transform .2s ease

	&.block
		display: block

	&.focused
		border: 1px solid #6118FD
		box-shadow: 0px 2px 8px rgba(150, 114, 255, 0.32)
	
	&.error
		box-shadow: 0px 2px 8px rgba($error, 0.32)
		border: 1px solid $error

.input__item:focus + .input__label,
.input__label.active
	font-size: 12px
	transform: translateY(calc(-50% - 12px))

.input.password
	padding-right: $padding-from-border + 24

.password-show__wrapper
	position: absolute
	right: $padding-from-border - 6
	top: 50%
	transform: translateY(calc(-50% + 2px))
	display: flex
	justify-content: center
	align-items: center
	cursor: pointer
	padding: 6px
</style>