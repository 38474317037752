<template>
	<svg-icon :icon-class="`eye${closed ? '-closed' : ''}`"></svg-icon>
</template>

<script>
export default {
	name: 'icon-trigger',
	props: {
		closed: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style>

</style>